import { combineReducers } from 'redux';

import company from './company'
import admin from './admin'
import controller from './controller'

const createRootReducer = (history) => combineReducers({
  company, admin, controller
});

export default createRootReducer