import React, {useState} from "react";
import Overlay from "../../../Overlay";
import {BriefcaseIcon, CakeIcon, DuplicateIcon} from "@heroicons/react/outline";
import moment from "moment-timezone"; 
import Avatar from 'assets/image.png'

const InnerContent = props => {
  const [clipboardTooltip, setClipboardTooltip] = useState(false)

  const handleClipboardCopyClick = async input => {
    await navigator.clipboard.writeText(input)
    setClipboardTooltip('Copied')
    setTimeout(()=>setClipboardTooltip(false), 1500)
  }

  return  <div className="grid grid-cols-3 my-4">
    <div className="col-span-1 mx-auto">
      <img src={props.photoUrl || Avatar} className="rounded-full h-28 w-28 object-cover" alt={props.name}/>
    </div>
    <div className="col-span-2 pl-2">
      <div>
        <span className="font-bold text-gray-700 text-3xl">{props.name}</span>
        {props.jobTitle && <span className="text-gray-500 ml-2 text-md">({props.jobTitle})</span>}
      </div>
      <div className="text-gray-500 mt-2">{props.email}<button onClick={()=>handleClipboardCopyClick(props.email)}>
        {clipboardTooltip ? <span className="ml-2 text-blue-700">{clipboardTooltip}</span> : <DuplicateIcon height={20} className="inline font-extrabold text-blue-500 hover:text-blue-700 transition-all ml-2"/>}</button></div>
      {props.lastSeenAt && <div className="text-gray-500 mt-2 text-sm">Last seen {moment(props.lastSeenAt).fromNow()}</div>}
      <div className="mt-8"/>
      {props.hiringDate && <div className="text-gray-500 mt-2"><BriefcaseIcon height={16} className="inline text-gray-700 mr-1"/> Joined {moment(props.hiringDate).fromNow()}</div>}
      {props.birthDate && <div className="text-gray-500 mt-2"><CakeIcon height={16} className="inline text-gray-700 mr-1"/> Birthday on {moment(props.birthDate).format('Do MMMM')}</div>}
    </div>
  </div>
}

export default function Profile(props) {
  const { onClose } = props
  
  return <Overlay scroll={false} close={onClose}>
      <InnerContent {...props} />
  </Overlay>
}