import React, {useEffect} from 'react'
import {useQuery} from "@apollo/client";

import {POST_QUERY} from "../../../graphql/company";
import Header from "../Header"
import {Link, useParams} from "react-router-dom";
import {ME_QUERY, USER_GRAPHCOMMENT_SSO} from "../../../graphql/user";
import styled from "styled-components";
import Sidebar from "../components/Sidebar";
import moment from "moment-timezone";

import PlaceholderUserImage from "../../../assets/image.png"
import Poll from "../components/Poll";

const PostContainer = styled.div`
  p, ul, ol {
    margin-bottom: 15px;
    margin-top: 10px;
  }

  ul, ol {
    padding-left: 15px;
  }

  ul {
    list-style: inside;
  }
  
  a {
    color: blue;
    text-decoration: underline;
    overflow-wrap: anywhere;
  }
`
export default function Post() {
  let params = useParams();
  const {postId} = params;

  const {data: meData} = useQuery(ME_QUERY)
  const {data: GCSSOData, loading: GCSSOLoading, error: GCSSOError} = useQuery(USER_GRAPHCOMMENT_SSO, {fetchPolicy: 'network-only'}) // graphcomment
  const {loading, error, data} = useQuery(POST_QUERY, {
    variables: {id: parseInt(postId)},
  });


  function SSO() {
    let __semio__token;
    let __semio__callback;
    
    function __semio__onload__sso() {
      window.__semio__helpers_sso({
        graphcommentId: 'Company-Page',
        publicKey: 'BAEPpp6daHMckPu2hadoXZGAce3QSdMFx4DyyP6xHAm++QLo4o=',
        data: GCSSOData?.UserGraphcommentSSO?.data,
        /**
         * onSuccess
         *
         * a function to handle the token generated
         *
         * @param {string} token       the generated token
         */
        onSuccess: function (token) {
          if (__semio__callback) {
            __semio__callback(token)
          }
          __semio__token = token;
          // console.warn("onSuccess should send the token safely to graphcomment please refer to subscribeToToken");
        },
        /**
         * onConflict
         *
         * a function to handle update conflicts
         */
        onConflict: function () {
        },
        /**
         * onFailure
         *
         * a function to handle update failures
         */
        onFailure: function () {
        }
      })
    }
      
      var __semio__params = {
        graphcommentId: `${process.env.NODE_ENV === 'production' ? "Company-Page" : "Company-Page"}`, // make sure the id is yours

        behaviour: {
          // HIGHLY RECOMMENDED
          //  uid: "...", // uniq identifer for the comments thread on your page (ex: your page id)
          uid: window.location.protocol + "//" + window.location.hostname + window.location.pathname
        },

        // configure your variables here

        auth: {
          /**
           * subscribeToToken
           *
           * a function which will reveice a callback C transmitting safely our authentication token
           * to the iframe.
           * C should be called everytime the authentication is changed
           *
           * @param {function} callback   callback take a string as a param
           */
          subscribeToToken: function (cb) {
            // console.warn("subscribeToToken should be implement according you authentication design")
            if (__semio__token) {
              cb(__semio__token);
            }
            __semio__callback = cb;
          },
          /**
           * signup
           */
          signup: function () {
            // console.warn("signup should be implement according you authentication design")

            // you can redirect to your login page:
            //window.location.href = '/signup';

            // or you can also open a modal for the user to signup
          },
          /**
           * login
           */
          login: function () {
            // console.warn("login should be implement according you authentication design")

            // you can redirect to your login page:
            //window.location.href = '/login';

            // or you can also open a modal for the user to login
          },
          /**
           * logout
           */
          logout: function () {
            // console.warn("logout should be implement according you authentication design")

            // you can redirect to your login page:
            //window.location.href = '/logout';

            // or you can also open a modal for the user to logout
          }
        }
      }
      
    const __semio__onload = () => {
      window.__semio__gc(__semio__params)
    }
    
    (function() {
      var gc = document.createElement('script');
      gc.type = 'text/javascript';
      gc.async = true;
      gc.onload = __semio__onload__sso;
      gc.defer = true;
      gc.src = 'https://integration.graphcomment.com/helpers_sso.js';
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(gc);
    })();
    (function() {
      var gc = document.createElement('script'); gc.type = 'text/javascript'; gc.async = true;
      gc.onload = __semio__onload; gc.defer = true; gc.src = 'https://integration.graphcomment.com/gc.js?' + Date.now();
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(gc);
    })();
  }

  // GraphComment
  useEffect(() => {
    if (GCSSOLoading) return
    SSO()
  }, [GCSSOLoading])

  if (error) {
    return <>Error loading... try again later</>
  }

  const post = data ? {
    id: data.post.id,
    body: data.post.body,
    createdAt: data.post.createdAt,
    title: data.post.title,
    coverImage: data.post.featuredImageUrl,
    author: data.post.user.name,
    authorImage: data.post.user.photoUrl,
    postType: data.post.postType
  } : {}

  const offices = meData?.me.company.offices || []

  return (
    <div className="min-h-screen bg-gray-100">
      <Header/>
      <div className="py-10 sm:py-4 -mt-24">
        <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
          <main className="lg:col-span-9 xl:col-span-8">
            <div className="px-4 sm:px-0">
              <div className="sm:hidden">
              </div>
              <div className="bg-white p-6 rounded-lg shadow-sm border">
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="sm:flex sm:space-x-5 w-full">
                    <div className="text-center sm:pt-1 sm:text-left w-full">
                      <p className="mb-5 text-xl font-bold text-gray-900 sm:text-2xl"><Link to="/"
                                                                                            className="inline-block text-blue-400 hover:text-blue-500 font-bold text-2xl mr-6">
                        ←
                      </Link>{post.title}</p>
                      <img src={post.coverImage}
                           className="text-xl shadow-xl rounded-lg font-bold text-gray-900 sm:text-2xl"/>
                      <div className="my-8 mx-12">
                        <div className="flex space-x-3 mr-6">
                          <div className="flex-shrink-0">
                            <Link to="/team">
                              <img className="object-cover h-10 w-10 rounded-full"
                                   src={post.authorImage || PlaceholderUserImage} alt=""/>
                            </Link>
                          </div>
                          <div className="min-w-0 grid grid-flow-col items-center">
                            <span className="text-sm font-medium text-gray-900 hover:underline">
                            <Link to="/team">
                              {post.author}
                            </Link>
                          </span>
                            <span className="ml-2 text-sm text-gray-500">
                              <Link to={`/posts/${post.id}`} className="hover:underline">
                                posted <time dateTime={post.createdAt}>{moment(post.createdAt).fromNow()}</time>
                              </Link>
                            </span>
                          </div>
                          <div className="flex-shrink-0 self-center flex">
                          </div>
                        </div>
                      </div>
                      <PostContainer>
                        <p className="mt-14 text-sm mb-10 mx-12 text-lg text-gray-600"
                           dangerouslySetInnerHTML={{__html: post.body}}/>
                      </PostContainer>
                      {post.postType === 'poll' && <Poll className="mt-14 text-sm mb-10 mx-12 text-lg text-gray-600" PostId={post.id}/>}
                      <div id="graphcomment"/>
                    </div>
                  </div>
                  <div className="mt-5 flex justify-center sm:mt-0">
                    {/*                  <a
                      href="#"
                      className="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      View profile
                    </a>*/}
                  </div>
                </div>
              </div>
              <div className="hidden sm:block">
              </div>
            </div>
          </main>
          <Sidebar offices={offices}/>
        </div>
      </div>
      <footer>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left">
            <span className="block sm:inline">&copy; 2021 CompanyPage Inc.</span>{' '}
            <span className="block sm:inline">All rights reserved.</span>
          </div>
        </div>
      </footer>
    </div>
  )
}