import React from "react"
import Clock from "react-live-clock";
import {MoonIcon, SunIcon} from "@heroicons/react/outline";
import moment from "moment-timezone";

const Time = (props) => {
  const {timeZone} = props

  const workingHours = (timeZone) => {
    const format = 'h:m A'
    const beforeTime = moment("8:00 AM", format)
    const afterTime = moment("8:00 PM", format)

    const time = moment(new Date().toLocaleTimeString('en-US', {timeZone: timeZone}), 'h:m A')
    return time.isBetween(beforeTime, afterTime);
  }

  return <>
    <Clock format={'hh:mm a'} ticking={true} timezone={timeZone}/>
    {
      workingHours(timeZone) ?
        <SunIcon style={{color: "#ffaa00"}} className="ml-4 mb-2 text-indigo-600 inline-block h-6"/> :
        <MoonIcon style={{color: "#6600ff"}} className="ml-4 mb-2 text-indigo-600 inline-block h-6"/>
    }
  </>
}

export default Time