import React from 'react'
import Links from "../../Links";
import Birthdays from "../Users/Birthdays";
import Hires from "../Users/Hires";
import Anniversary from "../Users/Anniversary";
import Offices from "../Users/Offices";

const Sidebar = (props) => {
  const {offices = []} = props

  return <aside className="hidden xl:block xl:col-span-4">
    <div className="top-4 space-y-4">
      <Links/>
      <Birthdays/> 
      <Hires/> 
      <Anniversary/>
      <Offices offices={offices} />
    </div>
  </aside>
}

export default Sidebar