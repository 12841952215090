import React from "react";
import {LinkIcon} from "@heroicons/react/solid"

import {useQuery} from "@apollo/client";

import {LINKS_QUERY} from "graphql/company";

import ContentLoader from "react-content-loader"

const Links = (props) => {
  const {loading, error, data} = useQuery(LINKS_QUERY);

  if (loading) return <div className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
    <ContentLoader
      speed={2}
      width={400}
      height={150}
      viewBox="0 0 400 150"
      backgroundColor="#CCCCCC"
      foregroundColor="#EEEEEE"
      {...props}
    >
      <circle cx="10" cy="20" r="8"/>
      <rect x="25" y="15" rx="5" ry="5" width="220" height="10"/>
      <circle cx="10" cy="50" r="8"/>
      <rect x="25" y="45" rx="5" ry="5" width="220" height="10"/>
      <circle cx="10" cy="80" r="8"/>
      <rect x="25" y="75" rx="5" ry="5" width="220" height="10"/>
      <circle cx="10" cy="110" r="8"/>
      <rect x="25" y="105" rx="5" ry="5" width="220" height="10"/>
    </ContentLoader>
  </div>;

  const links = data.links;
  
  if (data.links.length === 0){
    return null
  }

  return (
    <div className="top-4 space-y-4">
      <section aria-labelledby="who-to-follow-heading">
        <div className="bg-white rounded-lg shadow">
          <div className="p-6">
            <h2 id="who-to-follow-heading" className="text-base font-medium text-gray-900">
              Important Links
            </h2>
            <div className="mt-6 flow-root">
              <ul className="-my-4 divide-y divide-gray-200">
                <div>
                  {links.map(link => (
                    <li key={link.url}>
                      <a target="_blank" href={link.url} className="flex items-center py-4 space-x-3 hover:opacity-70 transition-all">
                        <div className="flex-shrink-0">
                          {link.imageUrl !== "" && link.imageUrl ?
                            <img className="h-8 w-8 rounded-full" src={link.imageUrl} alt=""/> :
                            <LinkIcon width={30} className="text-blue-400"/>}
                        </div>
                        <div className="min-w-0 flex-1">
                          <p className="text-sm font-medium text-blue-900">
                            <span>{link.title}</span>
                          </p>
                          <p className="text-sm text-gray-500">
                            {link.description}
                          </p>
                        </div>
                        <div className="flex-shrink-0">
                        </div>
                      </a>
                    </li>
                  ))}
                </div>
              </ul>
            </div>
            {/*          <div className="mt-6">
                    </div>*/}
          </div>
        </div>

      </section>
    </div>
  )
};

export default Links;