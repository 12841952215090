import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './index.css';

import { init, hide } from "./api";
window.cp = { init, hide };

ReactDOM.render(<App />, document.getElementById('root'));
