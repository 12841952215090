import React, {useEffect, useRef, useState} from "react";
import moment from "moment-timezone";
import {Link} from "react-router-dom";

import {useQuery} from "@apollo/client";

import {POSTS_QUERY} from "graphql/company";

import ContentLoader from "react-content-loader"

import {ChatIcon} from "@heroicons/react/outline";
import Poll from "../components/Poll"

import PlaceholderUserImage from "../../../assets/image.png"
import VoteCounter from "../components/Poll/voteCounter";

function renderPost(post, lastElement) {
  return <li ref={lastElement} key={post.id} className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
    <article aria-labelledby={'post-title-' + post.id}>
      <div>
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
            <Link to="/team">
              <img className="object-cover h-10 w-10 rounded-full"
                   src={post.user.photoUrl || PlaceholderUserImage} alt=""/>
            </Link>
          </div>
          <div className="w-full grid grid-flow-col grid-cols-2">
            <div className="grid w-full flex-1">
              <span className="text-sm font-medium text-gray-900 hover:underline">
                <Link to="/team">
                  {post.user.name}
                </Link>
              </span>
              <p className="text-sm text-gray-500">
                <Link to={`/posts/${post.id}`} className="hover:underline">
                  <time dateTime={post.createdAt}>{moment(post.createdAt).fromNow()}</time>
                </Link>
              </p>
            </div>
            {post.pinned &&
            <svg xmlns="http://www.w3.org/2000/svg" className="justify-self-end h-5 w-5 text-yellow-500" viewBox="0 0 20 20"
                 fill="currentColor">
              <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z"/>
            </svg>}
          </div>
          <div className="flex-shrink-0 self-center flex">
          </div>
        </div>
      </div>
      <Link to={`/posts/${post.id}`} onClick={  (event) => { post.postType === 'poll' && event.preventDefault() }} className={`text-blue-400 ${post.postType === 'poll' && "cursor-default"}`}>
        <div className={`${post.postType !== 'poll' && 'hover:opacity-80'} transition-al`}>
          {post.featuredImageUrl &&
          <img src={post.featuredImageUrl} className="w-full object-cover rounded-lg my-6"
               style={{maxHeight: "35rem"}}/>}
          <h2 id={'post-title-' + post.id} className="mt-4 text-2xl font-medium text-gray-900">
            {post.title}
          </h2>
          <div
            className="mt-2 text-md text-gray-700 space-y-4"
            // dangerouslySetInnerHTML={{ __html: post.body }}
          >
                  <span
                    dangerouslySetInnerHTML={{__html: post.excerpt}}/>
          </div>
          {post.postType === 'poll' && <Poll PostId={post.id}/>}
          <div className="grid grid-cols-2 mt-8 w-full">
            {post.postType === 'poll' ? <VoteCounter PostId={post.id}/> : <Link to={`/posts/${post.id}`} className="text-blue-400">
              Read More
            </Link>}
      
            <div className="inline text-sm ml-auto text-gray-500 vertical">
              <Link to={`/posts/${post.id}`} className="text-blue-400">
              <ChatIcon height={18} className="inline mr-2"/>
              <div className="gc-counter inline"
                   data-uid={`${process.env.NODE_ENV === 'production' ? 'https://app.company.page' : 'http://company.page.local:3000'}/posts/${post.id}`}>
                0
              </div>
              &nbsp; Comments
              </Link>
            </div>
          </div>
        </div>
      </Link>
    </article>
  </li>
}

//postType 0 = regular post, 1 = poll post
const Posts = (props) => {
  const {companyid} = props;
  const [postVariables, setPostVariables] = useState({offset: 0})
  const [posts, _setPosts] = useState([])
  const [endOfPosts, _setEndOfPosts] = useState(false)
  const _endOfPosts = useRef()
  const lastElement = useRef()
  const _posts = useRef()
  const _loadingMore = useRef()

  const setPosts = (posts) => {
    _setPosts(posts)
    _posts.current = posts
  }

  const setEndOfPosts = (state) => {
    _setEndOfPosts(state)
    _endOfPosts.current = state
  }

  const {loading, error, data, fetchMore} = useQuery(POSTS_QUERY, {variables: postVariables})
  const [loadingMore, _setLoadingMore] = useState(false)

  const setLoadingMore = (state) => {
    setTimeout(() => {
      _setLoadingMore(state)
      _loadingMore.current = state
    }, 1000)
  }

  const handleLoadMorePosts = () => {
    setLoadingMore(true)
    const clonedVariables = postVariables
    clonedVariables.offset = clonedVariables.offset + 10
    setPostVariables(clonedVariables)
    fetchMore({
        variables: clonedVariables
      }
    ).then(data => {
      setLoadingMore(false)
      if (!data || !data.data || data.data.posts.length === 0) {
        setEndOfPosts(true)
        return
      }
      let array = Array.from(_posts.current)
      array.push(...data.data.posts)
      setPosts(array)
      setTimeout(() => {
        window.__semio__helpers_counter('Company-Page')
      }, 0)
    })
  }

  function debounce(func, wait, immediate) {
    let timeout;
    return function () {
      const context = this, args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  function isVisible(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      (top + height) <= (window.pageYOffset + window.innerHeight) &&
      (left + width) <= (window.pageXOffset + window.innerWidth)
    );
  }

  const handleScroll = debounce(() => {
    if (lastElement?.current && isVisible(lastElement.current) && !_loadingMore.current === true && _endOfPosts.current !== true) {
      handleLoadMorePosts()
    }
  }, 250)

  useEffect(() => {
    if (window.__semio__helpers_counter) {
      window?.__semio__helpers_counter('Company-Page')
    }
    _posts.current = []
    window.addEventListener('scroll', handleScroll)
    return () => document.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    if (!loading && posts.length === 0) {
      setPosts(data.posts)

      function __semio__onload__counter() {
        window.__semio__helpers_counter(`${process.env.NODE_ENV === 'production' ? "Company-Page" : "Company-Page"}`)
      }

      (function () {
        var gc = document.createElement('script');
        gc.type = 'text/javascript';
        gc.async = true;
        gc.onload = __semio__onload__counter;
        gc.defer = true;
        gc.src = 'https://integration.graphcomment.com/helpers_counter.js?' + Date.now();
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(gc);
      })();
    }
  }, [loading])

  if ((loading && posts?.length === 0) || !companyid) return <div
    className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
    <ContentLoader
      speed={2}
      width={476}
      height={124}
      viewBox="0 0 476 124"
      backgroundColor="#CCCCCC"
      foregroundColor="#EEEEEE"
      {...props}
    >
      <rect x="48" y="8" rx="3" ry="3" width="88" height="6"/>
      <rect x="48" y="26" rx="3" ry="3" width="52" height="6"/>
      <rect x="0" y="56" rx="3" ry="3" width="410" height="6"/>
      <rect x="0" y="72" rx="3" ry="3" width="380" height="6"/>
      <rect x="0" y="88" rx="3" ry="3" width="178" height="6"/>
      <circle cx="20" cy="20" r="20"/>
    </ContentLoader>
  </div>;

  return (<>
      {/*pinned posts*/}
      {posts.map(post =>
        post.pinned === true && renderPost(post, lastElement)
      )}
      {/*rest of the posts*/}
      {posts.map(post =>
        post.pinned !== true && renderPost(post, lastElement)
      )}
      {loadingMore && <div className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
        <ContentLoader
          speed={2}
          width={476}
          height={124}
          viewBox="0 0 476 124"
          backgroundColor="#CCCCCC"
          foregroundColor="#EEEEEE"
          {...props}
        >
          <rect x="48" y="8" rx="3" ry="3" width="88" height="6"/>
          <rect x="48" y="26" rx="3" ry="3" width="52" height="6"/>
          <rect x="0" y="56" rx="3" ry="3" width="410" height="6"/>
          <rect x="0" y="72" rx="3" ry="3" width="380" height="6"/>
          <rect x="0" y="88" rx="3" ry="3" width="178" height="6"/>
          <circle cx="20" cy="20" r="20"/>
        </ContentLoader></div>}
    </>
  );
}

export default Posts;