import React, { useEffect } from 'react'
import {useQuery, useLazyQuery} from "@apollo/client";
import { ME_QUERY } from "graphql/user";
import PlaceholderImage from "../../assets/image.png"

import {POSTS_QUERY} from "../../graphql/company";
import Posts from "./Posts";
import Header from "./Header"
import Sidebar from "./components/Sidebar";

export default function New_Home() {
  const {data, loading, error} = useQuery(ME_QUERY)
  const [ loadCompanyInfo, {loading: postsLoading, error:postsError, data:postsData} ] = useLazyQuery(POSTS_QUERY, {
    variables: { id: parseInt(data?.me?.company.id) },
  });
  
  useEffect(()=>{
    if (data?.me && data?.me?.company && !postsData) {
      loadCompanyInfo()
    }
  }, [data])
  
  if (error) {
    return <>Error loading... try again later</>
  }

  const user = data ? {
    name: data.me.name,
    email: data.me.email,
    role: data.me.jobTitle,
    imageUrl: data.me.photoUrl,
    companyLogo: data.me.company.logoUrl,
    companyName: data.me.company.name
  } : {}

  const offices = data?.me.company.offices || []
  
  document.title = `${data?.me.company.name ? 'Home - ' + data?.me.company.name : 'Company Page'}`
  
  return (
    <div className="min-h-screen bg-gray-100">
     <Header />
      <div className="py-10 sm:py-4 -mt-24">
        <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
          <main className="lg:col-span-8 xl:col-span-8 lg:col-start-3">
            <div className="px-4 sm:px-0">
              <div className="sm:hidden">
              </div>
              <div className="bg-white p-6 rounded-lg shadow-sm border">
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="sm:flex sm:space-x-5">
                    <div className="flex-shrink-0">
                      <img className="object-cover mx-auto h-20 w-20 rounded-full" src={user.imageUrl || PlaceholderImage} alt="" />
                    </div>
                    <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                      <p className="text-sm font-medium text-gray-600">Welcome back,</p>
                      <p className="text-xl font-bold text-gray-900 sm:text-2xl">{user.name}&nbsp; 👋</p>
                      <p className="text-sm font-medium text-gray-600">{user.role}</p>
                    </div>
                  </div>
                  <div className="mt-5 flex justify-center sm:mt-0">
                    {/*                  <a
                      href="#"
                      className="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      View profile
                    </a>*/}
                  </div>
                </div>
              </div>
              <div className="hidden sm:block">
              </div>
            </div>
            <div className="mt-4">
              <h1 className="sr-only">Recent questions</h1>
              <ul className="space-y-4">
                <Posts companyid={data?.me.company.id || null}/>
              </ul>
            </div>
          </main>
          <Sidebar offices={offices}/>
        </div>
      </div>
      <footer>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left">
            <span className="block sm:inline">&copy; 2021 CompanyPage Inc.</span>{' '}
            <span className="block sm:inline">All rights reserved.</span>
          </div>
        </div>
      </footer>
    </div>
  )
}