import React from 'react'
import {POLL_RESPONSE, POST_QUERY} from "../../../../graphql/company";
import {useMutation, useQuery} from "@apollo/client";
import Loading from "../../../Loading";
import {CheckIcon} from "@heroicons/react/outline";

export default function Poll({PostId, className}) {

  const [pollResponse] = useMutation(POLL_RESPONSE)
  const {data = [], loading, error, refetch} = useQuery(POST_QUERY, {variables: {id: PostId}})

  const handleVote = (option) => {
    if (!data.post.active){
      return
    }
    
    pollResponse({variables: {answerId: option.id, selected: option.id !== data.post.selectedAnswer}}).then(r => {
      if (r.data.pollResponse.success === false) {
        return alert(r.data.pollResponse.error)
      }
      refetch()
    })
  }

  if (loading) return <Loading/>

  const totalVotes = data.post.poll.map(option => option.votes)?.reduce((a, c) => a + c, 0) || 0
  const percentage = (votes) => totalVotes > 0 ? `${Math.round((votes / totalVotes) * 100)}` : '0'
  

  return <>
    <div className={className || 'mt-4'}>
      {data?.post.poll.map((option) =>
        <div
          style={{backgroundImage: `${(data.post.selectedAnswer || !data.post.active) ? `linear-gradient(to right, rgba(239, 246, 255, 1) ${percentage(option.votes)}%, white ${percentage(option.votes)}%)` : 'none'}`}}
          className={`mt-2 grid border ${(option.id !== data.post.selectedAnswer && data.post.active) && 'cursor-pointer hover:border-gray-400 hover:text-blue-700'} ${(option.id === data.post.selectedAnswer) && 'cursor-pointer border-gray-400'} border-gray-300 p-4 transition-all rounded-md text-gray-700`}
          onClick={() => handleVote(option)}>
          <span
            className="grid grid-flow-col font-bold text-sm"><span>{option.body} {option.id === data.post.selectedAnswer && <>
            <CheckIcon className="inline text-white bg-gray-800 rounded-3xl mx-3"
                       height={18}/></>}</span>{(data.post.selectedAnswer || !data.post.active) &&
          <span className="inline justify-self-end">{percentage(option.votes) + '%'}</span>}</span>
        </div>
      )}
    </div>
  </>
}