import React, {useEffect, useState} from 'react'

const Loading = (props) => {
    const [visibility, setVisibility] = useState(false)
    let visibilityIn = setTimeout(() => { // we do not show the loading indicator unless more then 300ms have passed
        setVisibility(true)
    }, 300)

    useEffect(() => {
        return () => {
            clearTimeout(visibilityIn)
        }
    })

    return (<span
        className={`${visibility ? '' : 'hidden'} inline-flex rounded-md select-none cursor-default text-gray-400`}>
            <span
                className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-gray-400 focus:border-red-700 transition ease-in-out duration-150 cursor-default"
                disabled="">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                 fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
            <path className="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/></svg> Loading</span>
             </span>)
}

export default Loading