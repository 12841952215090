import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector, useStore} from "react-redux";
import {returnURL} from "../redux/actions/controller";

const Controller = (props) => {
  const store = useStore()
  const dispatch = useDispatch()
  const restoreURL = store.getState().controller.restoreURL
  
  if (restoreURL && window.location.href !== restoreURL){
    dispatch(returnURL(null))  
    
    window.location.href = restoreURL 
  }
  
  return (<>{props.children}</>)
}

export default Controller