import React, { useEffect } from "react";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import { useHistory } from "react-router-dom";

import { useQuery } from "@apollo/client";

import { THEME_QUERY } from "graphql/theme";

import ContentLoader from "react-content-loader";

import Header from './Header';
import Zone from './Zone';

import NewHome from "../New Home";

const Home = (props) => {
  const { loading, error, data } = useQuery(THEME_QUERY);
  
  document.title = 'Company Page'

  if (loading) return <div className="flex justify-center items-center min-h-screen">
      <ContentLoader 
        speed={2}
        width={400}
        height={150}
        viewBox="0 0 400 150"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <circle cx="10" cy="20" r="8" /> 
        <rect x="25" y="15" rx="5" ry="5" width="220" height="10" /> 
        <circle cx="10" cy="50" r="8" /> 
        <rect x="25" y="45" rx="5" ry="5" width="220" height="10" /> 
        <circle cx="10" cy="80" r="8" /> 
        <rect x="25" y="75" rx="5" ry="5" width="220" height="10" /> 
        <circle cx="10" cy="110" r="8" /> 
        <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
      </ContentLoader>
  </div>;

  const transform = (node) => {
    if (node.type === 'tag' && node.name === 'header') {
      return <Header key="header" {...props} />;
    }

    if (node.type === 'tag' && node.name === 'zone') {
      return <Zone key={`zone${node.attribs.id}`} {...props} zoneId={node.attribs.id} />;
    }
  }

  let html = data.theme.html;
  return <div><NewHome/>{/*ReactHtmlParser(html, { transform })*/}</div>;
};

export default Home;