import { gql } from '@apollo/client';

/*
  MUTATIONS
*/

const THEME_QUERY = gql`
  {
    theme {
      id
      html
    }
  }
`;

const ZONE_WIDGETS_QUERY = gql`
  query($zone: String!) {
    widgets( zone: $zone ) {
      id
      zone
      slug
    }
  }
`;

export { THEME_QUERY, ZONE_WIDGETS_QUERY };
