import {gql} from '@apollo/client';
//todo: make individual components for it
/*
  MUTATIONS
*/

const POLL_RESPONSE = gql`
  mutation pollResponse($answerId: Int!, $selected: Boolean!){
    pollResponse(input: {answerId: $answerId, selected: $selected}){
      success
      error
    }
  }
`

const ATTACHMENT_QUERY = gql`
  query attachment($id: Int!) {
    attachment(id: $id) {
      id
      name
      company
      fileName
    }
  }
`

const COMPANY_QUERY = gql`
  query fetchCompanies {
    me {
      id

      company {
        id
        name
        logoUrl
        users {
          id
          name
        }

        offices {
          id
          country
          fullCountryName
          timezone
          state
          city
          address
          address1
          address2
          zipCode
        }
      }
    }
  }
`;

const EMPLOYEE_QUERY = gql`
  query fetchEmployees{
    me {
      id
      email
      company {
        users{
          id
          name
          jobTitle
          email
          birthDate
          hiringDate
          lastSeenAt
          photoUrl
        }
      }
    }
  }`

const POSTS_QUERY = gql`
  query fetchPosts( $offset: Int, $limit: Int, $sortBy: sortBy ){
    posts(offset: $offset, limit: $limit, sortBy: $sortBy) {
      id
      title
      excerpt
      featuredImageUrl
      createdAt
      body
      pinned
      active
      poll
      postType
      user {
        name
        photoUrl
      }
    }
  }
`;

const OFFICE_QUERY = gql`
  query fetchOffices{
    offices {
      id,
      address1,
      address2,
      state,
      city,
      zipCode,
      country,
      fullCountryName,
      timezone,
    }
  }`

const POST_QUERY = gql`
  query($id: Int!) {
    post(id: $id) {
      id
      title
      excerpt
      body
      featuredImageUrl
      createdAt
      pinned
      poll
      selectedAnswer
      postType
      active
      user {
        name
        photoUrl
      }
    }
  }
`;

const VOTES_QUERY = gql`
  query Votes($PostId: Int!){
    votes(PostId: $PostId){
      id
      AnswerId
      PostId
      UserId
      User {
        id
        name
      }
      Answer {
        body
      }
    }
  }
`

const COMPANY_OAUTH_PROVIDERS_QUERY = gql`
  query CompanyOauthProviders {
    CompanyOauthProviders {
      providerList
      success
      error
    }
  }
`

const LINKS_QUERY = gql`
  query fetchLinks {
    links {
      id
      title
      description
      url
      backgroundColor
      imageUrl
      position
    }
  }
`;

const BIRTHDAYS_QUERY = gql`
  {
    usersWithUpcomingBirthdays {
      id
      email
      name
      photoUrl
      birthDate
    }
  }
`;

const NEW_HIRES_QUERY = gql`
  {
    newHires {
      id
      email
      name
      photoUrl
      hiringDate
    }
  }
`;

const WORK_ANNIVERSARY_QUERY = gql`
  {
    usersWithUpcomingWorkAnniversary {
      id
      email
      name
      photoUrl
      hiringDate
    }
  }
`;

const EMPLOYEES_FROM_INTEGRATIONS_QUERY = gql`
  {
    EmployeesFromIntegrations {
      employeeList
      success
    }
  }
`;

const CREATE_LINK = gql`
  mutation CreateLink( $title: String, $description: String, $position: Int, $url: String!, $imageUrl: String, $backgroundColor: String) {
    createLink(input: {title: $title, description: $description, position: $position, url: $url, imageUrl: $imageUrl, backgroundColor: $backgroundColor}){
      error
      success
      link {
        id
        title
        url
        description
      }
    }
  }`

const CREATE_OFFICE = gql`
  mutation CreateOffice($address1: String, $address2: String, $state: String, $city: String, $zipCode: String, $country: String) {
    createOffice(input: {address1: $address1, address2: $address2, city: $city, country: $country, state: $state, zipCode: $zipCode}) {
      error
      success
      office {
        id
        country
      }
    }
  }`

const CREATE_POST = gql`
  mutation createPost($title: String!, $body: String!, $featuredImageUrl: String, $pinned: Boolean!){
    createPost(input: {title: $title, body: $body, featuredImageUrl: $featuredImageUrl, pinned: $pinned}){
      error
      success
      post {
        id
        title
        excerpt
        body
        featuredImageUrl
        createdAt
      }
    }
  }`

const CREATE_POLL_POST = gql`
  mutation createPollPost($title: String!, $body: String!, $featuredImageUrl: String, $poll: [JSON]){
    createPollPost(input: {title: $title, body: $body, featuredImageUrl: $featuredImageUrl, poll: $poll}){
      error
      success
      post {
        id
        title
        excerpt
        body
        featuredImageUrl
        createdAt
      }
    }
  }`

const BULK_CREATE_EMPLOYEES = gql`
  mutation bulkCreateUsers($input: [CreateUserInput!]
  ){
    bulkCreateUsers(input: $input) {
      success
      error
    }
  }
`

const CREATE_EMPLOYEE = gql`
  mutation createEmployee(
    $name: String,
    $jobTitle: String
    $email: String!,
    $companyId: Int,
    $hiringDate: Date,
    $birthDate: Date,
    $roles: JSON,
    $photoUrl: String,
    $sub: String){
    createUser(input: {email: $email, name: $name, jobTitle: $jobTitle, companyId: $companyId, hiringDate: $hiringDate, birthDate: $birthDate, roles: $roles, photoUrl: $photoUrl, sub: $sub}){
      error
      success
      user {
        id
      }
    }
  }`

/*
const BULK_CREATE_EMPLOYEE = gql`
  mutation bulkCreateemployee(
    $name: String,
    $jobTitle: String
    $email: String!,
    $companyId: Int,
    $hiringDate: Date,
    $birthDate: Date,
    $roles: JSON,
    $photoUrl: String,
    $sub: String){
    bulkCreateUser(input: [{email: $email, name: $name, jobTitle: $jobTitle, companyId: $companyId, hiringDate: $hiringDate, birthDate: $birthDate, roles: $roles, photoUrl: $photoUrl, sub: $sub}]){
      error
      success
      user {
        id
      }
    }
  }`
*/

const CREATE_S3_SIGNED_REQUEST = gql`
  mutation createS3SignedRequest($fileName: String!){
    createS3SignedRequest(input: {fileName: $fileName}){
      error
      success
      signedRequest
    }
  }
`

const CREATE_ATTACHMENT = gql`
  mutation createAttachment($fileName: String!, $fileType: String!, $fileSize: Int!){
    createAttachment(input: {fileName: $fileName, fileType: $fileType, fileSize: $fileSize}){
      error
      success
    }
  }
`

const UPDATE_COMPANY = gql`
  mutation updateCompany($name: String, $logoUrl: String){
    updateCompany(input: {logoUrl: $logoUrl, name: $name}){
      error
      success
    }
  }`

const UPDATE_POST = gql`
  mutation updatePost($id: Int!, $title: String!, $body: String!, $featuredImageUrl: String, $pinned: Boolean!, $poll: [JSON], $active: Boolean){
    updatePost(input: {id: $id, title: $title, body: $body, featuredImageUrl: $featuredImageUrl, pinned: $pinned, poll: $poll, active: $active}){
      error
      success
      post {
        id
        title
        excerpt
        body
        featuredImageUrl
        updatedAt
        pinned
      }
    }
  }
`

const UPDATE_LINK = gql`
  mutation updateLink($id: Int!, $title: String, $description: String, $position: Int, $url: String, $imageUrl: String, $backgroundColor: String){
    updateLink(input: {id: $id, title: $title, description: $description, position: $position, url: $url, imageUrl: $imageUrl, backgroundColor: $backgroundColor}){
      error
      success
      link {
        id
        title
        url
        description
        position
      }
    }
  }`

const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee($id: Int!, $name: String, $jobTitle: String,
    $email: String!,
    $companyId: Int,
    $hiringDate: Date,
    $birthDate: Date, $photoUrl: String){
    updateUser(input:{id: $id, name: $name, jobTitle: $jobTitle, email: $email, companyId: $companyId, hiringDate: $hiringDate, birthDate: $birthDate, photoUrl: $photoUrl}){
      error
      success
      user {
        id
      }
    }
  }`

const UPDATE_OAUTH_PROVIDER = gql`
  mutation UpdateUserOauthProvider($accessToken: String, $refreshToken: String, $expiryDate: Date, $scope: String, $provider: String) {
    updateUserOauthProvider(input: {accessToken: $accessToken, refreshToken: $refreshToken, expiryDate: $expiryDate, scope: $scope, provider: $provider}) {
      error
      success
    }
  }
`

const UPDATE_OFFICE = gql`
  mutation UpdateOffice($id: Int!, $address1: String, $address2: String, $state: String, $city: String, $zipCode: String, $country: String){
    updateOffice(input: {id: $id, address1: $address1, address2: $address2, city: $city, country: $country, state: $state, zipCode: $zipCode}){
      error
      success
      office{
        id
      }
    }
  }`

const UPDATE_LINKS_ORDER = gql`
  mutation ReorderLinks($data: JSON!){
    reorderLinks(input: {data: $data}){
      error
      success
    }
  }`

const UPDATE_ATTACHMENT = gql`
  mutation UpdateAttachment($id: Int!, $fileName: String!){
    updateAttachment(input: {id: $id, fileName: $fileName }){
      error
      success
    }
  }
`


const DELETE_OFFICE = gql`
  mutation DeleteOffice($id: Int!) {
    deleteOffice(input: { id: $id}){
      error
      success
      deletedAt
    }
  }`


const DELETE_LINK = gql`
  mutation DeleteLink($id: Int!) {
    deleteLink(input: { id: $id}){
      error
      success
      deletedAt
    }
  }`

const DELETE_POST = gql`
  mutation DeletePost($id: Int!) {
    deletePost(input: { id: $id}){
      error
      success
      deletedAt
    }
  }`

const DELETE_EMPLOYEE = gql`
  mutation DeleteEmployee($id: Int!){
    deleteUser(input: {id: $id}){
      error
      success
      deletedAt
    }
  }`

const DELETE_ATTACHMENT = gql`
  mutation deleteAttachment($id: Int!){
    deleteAttachment(input: {id: $id}){
      error
      success
    }
  }
`

const DELETE_OAUTH_PROVIDER = gql`
  mutation deleteOauthProvider($name: String!){
    deleteUserOauthProvider(input: {name: $name}){
      success
      error
    }
  }
`

export {
  POLL_RESPONSE,
  CREATE_OFFICE,
  CREATE_S3_SIGNED_REQUEST,
  CREATE_ATTACHMENT,
  CREATE_LINK,
  CREATE_POST,
  CREATE_POLL_POST,
  DELETE_LINK,
  CREATE_EMPLOYEE,
  BULK_CREATE_EMPLOYEES,
  DELETE_OFFICE,
  DELETE_POST,
  DELETE_EMPLOYEE,
  DELETE_ATTACHMENT,
  DELETE_OAUTH_PROVIDER,
  UPDATE_OFFICE,
  UPDATE_COMPANY,
  UPDATE_EMPLOYEE,
  UPDATE_OAUTH_PROVIDER,
  UPDATE_POST,
  UPDATE_LINK,
  UPDATE_LINKS_ORDER,
  UPDATE_ATTACHMENT,
  ATTACHMENT_QUERY,
  COMPANY_OAUTH_PROVIDERS_QUERY,
  COMPANY_QUERY,
  POSTS_QUERY,
  OFFICE_QUERY,
  BIRTHDAYS_QUERY,
  NEW_HIRES_QUERY,
  EMPLOYEE_QUERY,
  LINKS_QUERY,
  POST_QUERY,
  VOTES_QUERY,
  WORK_ANNIVERSARY_QUERY,
  EMPLOYEES_FROM_INTEGRATIONS_QUERY
};
