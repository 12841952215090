import React from "react";

import {useQuery} from "@apollo/client";

import {NEW_HIRES_QUERY} from "graphql/company";

import ContentLoader from "react-content-loader"

import placeholderImg from "../../img/placeholder.jpeg";
import moment from "moment-timezone";

const Hires = (props) => {
  const {loading, error, data} = useQuery(NEW_HIRES_QUERY);

  if (loading) return <div  className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg mb-12">
    <ContentLoader
      speed={2}
      width={476}
      height={124}
      viewBox="0 0 476 124"
      backgroundColor="#CCCCCC"
      foregroundColor="#EEEEEE"
      {...props}
    >
      <rect x="48" y="8" rx="3" ry="3" width="88" height="6"/>
      <rect x="48" y="26" rx="3" ry="3" width="52" height="6"/>
      <rect x="0" y="56" rx="3" ry="3" width="330" height="6"/>
      <rect x="0" y="72" rx="3" ry="3" width="300" height="6"/>
      <rect x="0" y="88" rx="3" ry="3" width="178" height="6"/>
      <circle cx="20" cy="20" r="20"/>
    </ContentLoader>
  </div>;

  const hiredUsers = data.newHires.map(user => user.name || user.email);

  if (hiredUsers.length == 0) return null;

  const formatJoining = (date) => {
    function isToday(theDate){
      return moment(theDate).date() === moment().date() && moment(theDate).month() === moment().month()
    }

    if (isToday(new Date(date))) {
      return <div>Today &nbsp;💪</div>
    }

    return <div>{moment(date).format('MMMM Do')}</div>
  }

  return (
    <section aria-labelledby="trending-heading">
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h2 id="who-to-follow-heading" className="text-base font-medium text-gray-900">
            New Joiners &nbsp; 👋
          </h2>
          <div className="flow-root">
            <div className="">

              <div className="relative z-0 overflow-hidden">
                <div className="mb-6">
                  <p className="mb-4 text-gray-500 text-base">
          <span className="align-middle leading-1">
           Say hello to folks who joined recently.
          </span>
                  </p>
                </div>
                {data.newHires.map(user => (
                  <div className="flex flex-row mt-3" key={user.id}>
                    <img key={`newHirePic${user.id}`}
                         className="relative object-cover inline-block z-0 h-16 w-16 rounded-full ring-2 ring-white"
                         src={user.photoUrl || placeholderImg} alt={user.name}/>
                    <span className="ml-3 text-gray-600 self-center"> 
                      {user.name}
                      <div className="text-gray-500 text-sm">{formatJoining(user.hiringDate)}</div>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hires;