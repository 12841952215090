import { gql } from '@apollo/client';

/*
  MUTATIONS
*/

const SIGN_IN_USER = gql`
  mutation SignInUser($email: String!, $password: String!) {
    signInUser(input: { email: $email, password: $password }) {
      success
      error
      token
    }
  }
`;

const SIGN_IN_GOOGLE_USER = gql`
  mutation SignInWithGoogleToken( $token: String!) {
    signInWithGoogleToken(input: { token: $token }) {
      success
      showOnboarding
      error
      token
    }
  }
`;

const CREATE_USER = gql`
  mutation CreateUser($email: String!, $password: String!) {
    createUser(input: { email: $email, password: $password }) {
      success
      error
      token
      user {
        id
        email
      }
    }
  }
`;

const ME_QUERY = gql`
  {
    me {
      id
      email
      name
      photoUrl
      roles
      jobTitle
      company {
        id
        name
        logoUrl

        offices {
          id
          country
          fullCountryName 
          timezone
        }
      }
    }
  }
`;

const USER_GRAPHCOMMENT_SSO = gql`
  {
    UserGraphcommentSSO {
      data
      error
      success
    }
  }`


export { SIGN_IN_USER, SIGN_IN_GOOGLE_USER, CREATE_USER, ME_QUERY, USER_GRAPHCOMMENT_SSO };
