import React from 'react'
import Time from "../Time";

const Offices = props => {
  const { offices = [] } = props
  
  if (offices.length === 0){
    return null
  }
  
  return <section aria-labelledby="trending-heading">
    <div className="bg-white rounded-lg shadow">
      <div className="p-6">
        <h2 id="locations" className="text-base font-medium text-gray-900">
          Locations &nbsp; 📍
        </h2>
        <div className="mt-2 flow-root">
          {offices.map(office => (
            <div key={`office${office.id}`} className="py-4">
              <span className="text-gray-80 text-md font-light">{office.fullCountryName}</span>
              <div className="uppercase subpixel-antialiased font-medium text-3xl text-gray-800">
                <div className="inline-block"/>
                <Time timeZone={office.timezone}/>
              </div>
            </div>
          ))}
        </div>
        {/*                    <div className="mt-6">
                    </div>*/}
      </div>
    </div>
  </section>
}

export default Offices