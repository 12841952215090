import React, {useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";

import { useQuery } from "@apollo/client";

import { ME_QUERY } from "graphql/user";

import Home from "components/Home";
import Post from "components/New Home/Post";
import Team from "components/New Home/Team"

import ContentLoader from "react-content-loader"
import NotFound from "../components/NotFound";

const LoggedIn = (props) => {
  const { loading, error, data } = useQuery(ME_QUERY);
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (loading) return <div className="flex justify-center items-center min-h-screen">
      <ContentLoader 
        speed={2}
        width={400}
        height={150}
        viewBox="0 0 400 150"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <circle cx="10" cy="20" r="8" /> 
        <rect x="25" y="15" rx="5" ry="5" width="220" height="10" /> 
        <circle cx="10" cy="50" r="8" /> 
        <rect x="25" y="45" rx="5" ry="5" width="220" height="10" /> 
        <circle cx="10" cy="80" r="8" /> 
        <rect x="25" y="75" rx="5" ry="5" width="220" height="10" /> 
        <circle cx="10" cy="110" r="8" /> 
        <rect x="25" y="105" rx="5" ry="5" width="220" height="10" /> 
      </ContentLoader>
  </div>;

  if (error || !data || !data.me) {
    if (error) {
      localStorage.setItem("company_page:lastError", JSON.stringify(error)); // for debugging
    }

    if ((!localStorage.getItem('company_page:token') && error ) || (error && (JSON.stringify(error).includes('Json') || JSON.stringify(error).includes('UNAUTHENTICATED') || JSON.stringify(error).includes('INTERNAL_SERVER_ERROR')))) {
      localStorage.removeItem("company_page:token");

      return (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      );
    }
  }

  const childProps = {
    currentUser: data.me,
    ...props
  };

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/">
          <Home {...childProps} />
        </Route>

        <Route exact path="/posts/:postId">
          <Post {...childProps} />
        </Route>
        <Route path="/team">
          <Switch>
            <Route exact path="/team/">
              <Team {...childProps}/>
            </Route>
            <Route exact path="/team/:id">
              <Team {...childProps}/>
            </Route>
            <Route>
              <NotFound/>
            </Route>
          </Switch>
        </Route>
        <Route path="*">
          <NotFound/>
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default LoggedIn;