import React, {useEffect, useState} from 'react'
import {useQuery} from "@apollo/client";
import {Link, useHistory, useParams} from "react-router-dom";
import {EMPLOYEE_QUERY} from "../../../graphql/company";
import image from "../../../assets/image.png"

import Header from "../Header";
import Profile from "./Profile";
import {ME_QUERY} from "../../../graphql/user";

// the team directory
const Team = (props) => {
  const history = useHistory()
  const params = useParams()
  const {loading, error, data} = useQuery(EMPLOYEE_QUERY)
  const {data:meData, loading:meLoading, error:meError} = useQuery(ME_QUERY)
  const [employees, setEmployees] = useState([])
  const [profileOverlay, setProfileOverlay] = useState(false)

  document.title = 'Team / Company Page'

  useEffect(() => {
    if (loading || !data || !data.me.company.users) return;
    setEmployees(data.me.company.users)
  }, [loading, data])

  const handleProfileClick = employee => {
    setProfileOverlay(employee)
  }
  
  if (error) {
    history.replace('/')
    return (<></>)
  }

  document.title = `${meData?.me.company.name ? 'Team Directory ✌ - ' + meData?.me.company.name  : 'Team Directory ✌'}`
  
  return (<>
    <div className="min-h-screen bg-gray-100">
      <Header/>
      {profileOverlay && <Profile {...profileOverlay} onClose={() => setTimeout(()=>setProfileOverlay(false), 300)}/>}
      <div className="py-10 sm:py-4 -mt-24">
        <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
          <main className="lg:col-span-12 xl:col-span-12 bg-white rounded-lg p-9 shadow-lg">
            <div className="mb-6">
              <h1 className="text-3xl font-bold">Team Directory ✌️</h1>
              <div className="text-base pt-2 font-normal text-gray-700">This section helps us keep a track of who's who.
                🙌
              </div>
            </div>
            <div
              className="overflow-hidden pt-8 gap-2 divide-gray-200 xl:grid-cols-6 lg:grid-cols-4 sm:grid md:grid-cols-4 ">
              {employees.map(employee => (
                <button key={`link${employee.id}`} onClick={()=>handleProfileClick(employee)}
                      className="relative group rounded bg-white m-1 rounded-lg overflow-hidden rounded w-40 hover:opacity-100 opacity-90 transition-all"
                      style={{backgroundColor: "rgba(102, 102, 102, 0.05)"}}>
                  <div className="h-40 ">
                      <span className="inline-flex text-teal-700 p-6 h-full w-full">
                        <img alt={`${employee.name}'s profile photo`} src={employee.photoUrl || image}
                 className="block w-full h-full  shadow-lg border-white border-2 rounded-lg rounded-full object-cover"/>
                      </span> 
                  </div>
                  <div className="px-3 pb-3">
                    <h3 className="text-lg text-gray-800 font-bold text-center">
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      {employee.name}
                    </h3>
                    <div className="pt-0.5 mb-3 text-sm font-medium text-center text-gray-700">
                      <span className="">{employee.jobTitle ?  employee.jobTitle : <>&nbsp;</>}</span></div>
                  </div>
                  <span className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                        aria-hidden="true">
        </span>
                </button>
              ))}
            </div>
          </main>
        </div>
      </div>
      <footer>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left">
            <span className="block sm:inline">&copy; 2021 CompanyPage Inc.</span>{' '}
            <span className="block sm:inline">All rights reserved.</span>
          </div>
        </div>
      </footer>
    </div>
  </>)

}

export default Team