import React from "react";
import Clock from 'react-live-clock';
import { Link } from "react-router-dom";
import Dropdown from "./components/Dropdown"

import placeholderImg from "./img/placeholder.jpeg";

const Header = (props) => {
  const { currentUser } = props;
  const { company } = currentUser;
  const { offices } = company;

  return (
    <nav className="bg-white shadow sticky top-0 z-10">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-24">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/">
                { company.logoUrl !== "" ?
                  <img className="hidden lg:block h-10 w-auto" src={company.logoUrl} alt={company.name}/> :
                  <div className="font-bold text-2xl text-gray-700 select-none">{company.name}</div>
                }
              </Link>
            </div>
          </div>

          <div className="flex items-center">
            {offices.map(office => (
              <div key={`office${office.id}`} className="px-6">
                <span className="uppercase text-gray-400 text-sm font-semibold">{office.fullCountryName}</span>
                <div className="subpixel-antialiased text-2xl text-gray-500">
                  <Clock format={'hh:mm a'} ticking={true} timezone={office.timezone}/>
                </div>
              </div>
            ))}
          </div>

          <div className="flex items-center justify-center"> 
            <span className="uppercase text-gray-400 text-sm font-semibold">
              <Dropdown photoUrl={currentUser.photoUrl || placeholderImg} roles={currentUser.roles} email={currentUser.email} userName={currentUser.name}/> 
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;