// things saved here are saved to local storage too 
const initialState = {restoreURL: ""}

export default function controller(state = initialState, action) {
  switch (action.type) {
    case 'RESTORE_URL':
      return {...state, restoreURL: action.url}
    default:
      return state
  }
}