import { createStore } from 'redux'
import { createBrowserHistory } from 'history';
import { loadState, saveState } from "./localstorage";

import createRootReducer from 'redux/reducers';

const history = createBrowserHistory();
const persistedState = loadState();

export const store = createStore(
  createRootReducer(history), persistedState
);

store.subscribe(() => {
  //saving only controller state
  saveState({controller: store.getState().controller});
});