import React from 'react'
import {POST_QUERY} from "../../../../graphql/company";
import {useQuery} from "@apollo/client";
import Loading from "../../../Loading";

export default function VoteCounter({PostId, className}) {

  const {data = [], loading, error} = useQuery(POST_QUERY, {variables: {id: PostId}})

  if (loading) return <Loading/>

  const totalVotes =  data.post.poll.map(option => option.votes)?.reduce((a,c) => a + c, 0) || 0

  return <div>
    <span className="text-gray-700">{totalVotes} votes {data?.post?.active === false && '• Poll Closed'}</span>
  </div>
}