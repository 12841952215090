const initialState = {
  id: 1,
  name: "Company Acme"
}

export default function company(state = initialState, action) {
  switch (action.type) {
    case 'INIT_COMPANY':
      console.log("INIT_COMPANY");
      return state

    default:
      return state
  }
}