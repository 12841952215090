import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import client from 'graphql/client';

import { Provider } from 'react-redux';
import { store } from 'redux/store';
import Controller from "./containers/controller";

import LoggedIn from "containers/LoggedIn" 
const Authorization = lazy(() => import( 'components/Admin/utils/authorization'));
const SignOut = lazy(() => import('components/Auth/SignOut'))
const SignIn = lazy(() => import('components/Auth/SignIn'));
const SignUp = lazy(() => import('components/Auth/SignUp'))
const Admin = lazy(()=> import('components/Admin'))
const OnBoarding = lazy(()=> import('./components/Admin/containers/Onboarding')) 

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}> 
        <Controller/>
          <Router>
            <Switch>
              <Route path="/login">
                <Suspense fallback={null}>
                  <SignIn/>
                </Suspense>
              </Route>
              <Route path="/signup">
                <Suspense fallback={null}>
                  <SignUp/>
                </Suspense>
              </Route>
              <Route path="/logout">
                <Suspense fallback={null}>
                  <SignOut/>
                </Suspense>
              </Route>
              <Route path="/admin">
                <Suspense fallback={null}>
                  <Authorization>
                      <Route path="/admin/" component={Admin} exact/>
                      <Route path="/admin/:settingName" component={Admin} exact/>
                      <Route path="/admin/:settingName/:id" component={Admin} exact/>
                      <Route path="/admin/:settingName/:id/:edit" component={Admin} exact/>
                      <Route path="/admin/onboarding/:step" component={OnBoarding} exact />
                  </Authorization>
                </Suspense>
              </Route>
              <Route path="/">
                  <LoggedIn/>
              </Route>
            </Switch>
          </Router>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
